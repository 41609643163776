import { post, put } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_SERVICE_SCRIPT = "/api/Service/V1/script";
const API_SERVICE_QUERY_PARAMS = "/api/Prueba/V1/query_by_params";

const API_SERVICE = "/api/sivi_data/V1";
const API_PUBLIC = "/api/Prueba/V1/query";
const API_SERVICE_ARRAY = "/api/sivi_data/ServiceArray/V1";

const API_SERVICE_PUBLIC_MULTIQUERY = "/api/public/V1/multiquery";
const API_SERVICE_MULTIQUERY = "/api/Service/V1/multiquery";
const API_SERVICE_REPORTEV2_COLUMNS_V2 = "/api/sivi_data/V1/descargar_reporteV2Column_V2";
const API_SERVICE_PUBLICO_REPORTEV2 = "/api/Prueba/downloadCSV";

export default {
  getAll: (payload) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_indicador",
          type: "string"
        }
      ]
    };

    if (payload?.currentPage) {
      request._petitions.push({
        name: "@page",
        value: payload.currentPage,
        type: "int"
      });
    }

    if (payload?.filter && payload.filter !== "all") {
      const { filter, search } = payload;

      request._petitions.push({
        name: "@filtros_AND",
        value: "i.activo",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `${filter}`,
        type: "string"
      });

      request._petitions.push({
        name: "@filtros_OR",
        value: "i.denominacion|i.abreviatura|g.denominacion",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%|%${search}%`,
        type: "string"
      });
    } else if (payload?.search) {
      const { search } = payload;

      request._petitions.push({
        name: "@filtros_OR",
        value: "i.denominacion|i.abreviatura|g.denominacion",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%|%${search}%`,
        type: "string"
      });
    }

    return post(API_SERVICE_QUERY, request);
  },

  getAllPublic: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_indicador",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getFuenteMapa: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "q_web_obtener_fuente_mapa_publico",
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getWithGroup: (anio) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_indicador_orberby_grupo",
          type: "string"
        },
        {
          name: "@ANIO",
          value: `%${anio}%`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getWithGroupFecha: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "q_web_select_fecha_sien_anemia",
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getWithGroupFechaGestantes: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "q_web_select_fecha_gestantes",
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getEstadisticaIndicador: (sigla) => {
    var $tabla = `sivi_data.${sigla}`;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_indicador_estadistica",
          type: "string"
        },
        {
          name: "@TABLE",
          value: $tabla,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  editOneQuery: (payload, idquery, isUnique) => {
    const request = {
      _type: 2,
      _table: "query",
      _petitions: [
        {
          name: "denominacion",
          value: payload.query,
          type: isUnique ? "string|unique" : "string"
        },
        {
          name: "descripcion",
          value: payload.descripcion,
          type: "string"
        }
      ]
    };

    return put(API_SERVICE + "/" + idquery, request);
  },

  changeState: (payload) => {
    const request = {
      _type: 3,
      _table: "ind_indicador",
      _petitions: [
        {
          name: "@ids",
          value: payload.ids.join("|"),
          type: "string"
        },
        {
          name: "activo",
          value: payload.activos.join("|"),
          type: "string"
        }
      ]
    };

    return put(API_SERVICE_ARRAY, request);
  },

  deleteOne: (idindicador) => {
    const request = {
      _type: 3,
      _table: "ind_indicador",
      _petitions: [
        {
          name: "eliminado",
          value: "1",
          type: "int"
        }
      ]
    };

    return put(API_SERVICE + "/" + idindicador, request);
  },

  // -------- Consulta pública --------
  getPeriodos: () => {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "web_data_periodo",
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getProvincias: () => {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "web_data_provincias",
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getDistritos: (provincia) => {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "web_dawh_distritos",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "provincia",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${provincia}%`,
          type: "string"
        }
      ]
    };
    // algoooo

    return post(API_PUBLIC, request);
  },

  getInfo(idindicador) {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "web_data_info_indicador",
          type: "string"
        },
        {
          name: "@IDINDICADOR",
          value: `${idindicador}`,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_QUERY_PARAMS, request);
  },

  getIndicador: (distrito, idperiodo, sigla, provincia, quintil = "") => {
    var $tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value:
            quintil == ""
              ? "sp_obtener_indicador_con_establecimiento_v2"
              : "sp_obtener_indicador_con_establecimiento_quintil_v2",
          type: "string"
        },
        {
          name: "@TABLE",
          value: $tabla,
          type: "int"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@IDPERIODO",
          value: `%${idperiodo}%`,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: `%${quintil}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_MULTIQUERY, request);
  },

  getIndicador_fin2: (distrito, idperiodo, provincia) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_detalle_ind_registro_fin2",
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@IDPERIODO",
          value: `%${idperiodo}%`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getIndicador_fcg1: (distrito, idperiodo, provincia) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_detalle_ind_registro_fcg1",
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@IDPERIODO",
          value: `%${idperiodo}%`,
          type: "string"
        },
        {
          name: "@TABLE",
          value: `sivi_data.ind_registro_fin3`,
          type: "int"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getIndicador_estadistica: (distrito, idperiodo, provincia, tabla) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_detalle_ind_registro_estadistica",
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@IDPERIODO",
          value: `%${idperiodo}%`,
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getIndicador_estadisticaFechaCorte: (distrito, idperiodo, provincia, tabla, fecha_corte) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_detalle_ind_registro_estadistica_fecha_corte",
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@IDPERIODO",
          value: `%${idperiodo}%`,
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@FECHA_CORTE",
          value: `${fecha_corte}`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getIndicador_estadistica_v2: (anio, provincia, distrito) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "sp_web_query_obtenersienanemia_public",
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@ANIO",
          value: `%${anio}%`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getAllIndicadores: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "data_select_indicadores_all",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getIndicadorIndicador: (distrito, sigla, provincia, periodo_ini, periodo_fin, quintil = "", periodoini, periodofin) => {
    var $tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value:
            quintil == ""
              ? "web_data_detalle_indicador_distrito"
              : "web_data_detalle_indicador_distrito_quintil",
          type: "string"
        },
        {
          name: "@TABLE",
          value: $tabla,
          type: "int"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@PERIDO_INI",
          value: periodo_ini,
          type: "string"
        },
        {
          name: "@PERIOIDO_FIN",
          value: periodo_fin,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: quintil,
          type: "string"
        },
        {
          name: "@PER_FILT_INI",
          value: periodoini,
          type: "int"
        },
        {
          name: "@PER_FILT_FIN",
          value: periodofin,
          type: "int"
        },
      ]
    };

    return post(API_SERVICE_PUBLIC_MULTIQUERY, request);
  },

  
  getIndicadorIndicadorInterno: (distrito, sigla, provincia, periodo_ini, periodo_fin, quintil = "") => {
    var $tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value:
            quintil == ""
              ? "web_data_detalle_indicador_distrito_interno"
              : "web_data_detalle_indicador_distrito_quintil_interno",
          type: "string"
        },
        {
          name: "@TABLE",
          value: $tabla,
          type: "int"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@PERIDO_INI",
          value: periodo_ini,
          type: "string"
        },
        {
          name: "@PERIOIDO_FIN",
          value: periodo_fin,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: quintil,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLIC_MULTIQUERY, request);
  },

  getperiodoByIndicador: (sigla) => {
    var $tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "q_web_select_periodo_evaluacion",
          type: "string"
        },
        {
          name: "@TABLE",
          value: $tabla,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_QUERY_PARAMS, request);
  },

  getPeriodoBySigla: (periodo, idindicador) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "q_web_select_ind_periodoevaluacion",
          type: "string"
        },
        {
          name: "@PERIODO",
          value: `%${periodo}%`,
          type: "string"
        },
        {
          name: "@IDINDICARDOR",
          value: `${idindicador}`,
          type: "string"
        }
      ]
    };

    console.log(request);

    return post(API_SERVICE_QUERY_PARAMS, request);
  },

  getperiodoByByIDIndicador: (idindicador) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_procedure_indicador",
          type: "string"
        },
        {
          name: "@IDINDICADOR",
          value: idindicador,
          type: "int"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getperiodoByByIDPerido: (idperiodo) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_procedure_idperiodoevaluacion",
          type: "string"
        },
        {
          name: "@IDPERIODOEVALUACION",
          value: idperiodo,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getIndicadorByIdperiodoMeses: (distrito, idindicador, sigla, provincia, idperiodo) => {
    var $tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_indicador_periodoevaluacion",
          type: "string"
        },
        {
          name: "@TABLE",
          value: $tabla,
          type: "int"
        },
        {
          name: "@IDPERIODO",
          value: `%${idperiodo}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY_PARAMS, request);
  },

  getIndicadorByIdperiodo: (distrito, idindicador, sigla, provincia, idperiodo, quintil = "") => {
    var $tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value:
            quintil == ""
              ? "web_data_indicador_periodoevaluacion"
              : "web_data_indicador_periodoevaluacion_quintil",
          type: "string"
        },
        {
          name: "@TABLE",
          value: $tabla,
          type: "int"
        },
        {
          name: "@IDPERIODO",
          value: `%${idperiodo}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: `%${quintil}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY_PARAMS, request);
  },
  getResumen: () => {},

  getAllTipoFicha: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_tipofichatecnica",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllGrupoInd: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_grupoindicador",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  addOne: (payload) => {
    const request = {
      _type: 2,
      _table: "ind_indicador",
      _petitions: [
        {
          name: "denominacion",
          value: payload.denominacion,
          type: "string|unique"
        },
        {
          name: "tituloreporte",
          value: payload.tituloreporte,
          type: "string"
        },
        {
          name: "titulonumerado",
          value: payload.titulonumerado,
          type: "string"
        },
        {
          name: "titulodenominado",
          value: payload.titulodenominado,
          type: "string"
        },
        {
          name: "abreviatura",
          value: payload.abreviatura,
          type: "string"
        },
        {
          name: "idtipofichatecnica",
          value: payload.idtipofichatecnica,
          type: "string"
        },
        {
          name: "idgrupo",
          value: payload.idgrupo,
          type: "string"
        },
        {
          name: "idquery",
          value: payload.idquery,
          type: "string"
        },
        {
          name: "sigla",
          value: payload.sigla,
          type: "string"
        },
        {
          name: "activo",
          value: "0",
          type: "int"
        }
      ]
    };

    return post(API_SERVICE, request);
  },

  editOne: (payload, idindicador, isUnique) => {
    const request = {
      _type: 2,
      _table: "ind_indicador",
      _petitions: [
        {
          name: "denominacion",
          value: payload.denominacion,
          type: isUnique ? "string|unique" : "string"
        },
        {
          name: "tituloreporte",
          value: payload.tituloreporte,
          type: "string"
        },
        {
          name: "titulonumerado",
          value: payload.titulonumerado,
          type: "string"
        },
        {
          name: "titulodenominado",
          value: payload.titulodenominado,
          type: "string"
        },
        {
          name: "abreviatura",
          value: payload.abreviatura,
          type: "string"
        },
        {
          name: "idtipofichatecnica",
          value: payload.idtipofichatecnica,
          type: "string"
        },
        {
          name: "idgrupo",
          value: payload.idgrupo,
          type: "string"
        },
        {
          name: "sigla",
          value: payload.sigla,
          type: "string"
        },
        {
          name: "idquery",
          value: payload.idquery,
          type: "string"
        },
        {
          name: "anio",
          value: payload.anio,
          type: "string"
        }
      ]
    };

    return put(API_SERVICE + "/" + idindicador, request);
  },

  executeScript: (script, fecha_ini, fecha_fin, idperiodo, idparametro) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: script,
          type: "string"
        },
        {
          name: "@FECHA_INI",
          value: fecha_ini,
          type: "string"
        },
        {
          name: "@FECHA_FIN",
          value: fecha_fin,
          type: "string"
        },
        {
          name: "@IDPERIODO",
          value: idperiodo,
          type: "int"
        },
        {
          name: "@IDINDICADOR",
          value: idparametro,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_SCRIPT, request);
  },

  getAllByProvincia: (sigla, provincia, periodo_ini, periodo_fin, quintil = "", periodoini, periodofin) => {
    var tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value:
            quintil == ""
              ? "web_data_select_grafica_provincia"
              : "web_data_select_grafica_provincia_quintil",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@PERIDO_INI",
          value: periodo_ini,
          type: "string"
        },
        {
          name: "@PERIOIDO_FIN",
          value: periodo_fin,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: quintil,
          type: "string"
        },
        {
          name: "@PER_FILT_INI",
          value: periodoini,
          type: "int"
        },
        {
          name: "@PER_FILT_FIN",
          value: periodofin,
          type: "int"
        },
      ]
    };

    return post(API_PUBLIC, request);
  },

  getAllByProvinciaIdPeriodo: (sigla, provincia, idperiodo) => {
    var tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_provincia_idperiodoevaluacion",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@IDPERIODO",
          value: `%${idperiodo}%`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getAllByProvinciaIdPeriodoFechaCorte: (sigla, provincia, idperiodo, fecha_corte) => {
    var tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_provincia_idperiodoevaluacion_fecha_corte",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@IDPERIODO",
          value: `%${idperiodo}%`,
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: `${fecha_corte}`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getAllByProvinciaIdPeriodo_v2: (anio, provincia, distrito) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "sp_web_query_obtenersienanemia_public_provincia",
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@ANIO",
          value: `%${anio}%`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getAllByDistritIdPeriodo: (sigla, provincia, idperiodo) => {
    var tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_distrito_byperiodo",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@IDPERIODO",
          value: `%${idperiodo}%`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getAllByDistritIdPeriodoFechaCorte: (sigla, provincia, idperiodo, fecha_corte) => {
    var tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_distrito_byperiodo_fecha_corte",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@IDPERIODO",
          value: `%${idperiodo}%`,
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: `${fecha_corte}`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getPorcentajeColor: (idindicador) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_indicador_color",
          type: "string"
        },
        {
          name: "@IDINDICADOR",
          value: idindicador,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getIndicadoresByIDindicador: (idindicador) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_idperiodoevaluacion_idindicador",
          type: "string"
        },
        {
          name: "@IDINDICADOR",
          value: idindicador,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_MULTIQUERY, request);
  },

  getIndicadoresByIDindicadorv2: (idindicador) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_procedure_indicador",
          type: "string"
        },
        {
          name: "@IDINDICADOR",
          value: idindicador,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_MULTIQUERY, request);
  },

  getPaqueteIndicadorMC1: (idperiodo, distrito, provincia, quintil = "") => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value:
            quintil == ""
              ? "web_data_get_paquete_indicador_mc1"
              : "web_data_get_paquete_indicador_mc1_quintil",
          type: "string"
        },
        {
          name: "@IDPERIODO",
          value: `%${idperiodo}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: `%${quintil}%`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getPaqueteIndicadorMC2: (idperiodo, distrito, provincia, quintil = "") => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value:
            quintil == ""
              ? "web_data_get_paquete_indicador_mc2"
              : "web_data_get_paquete_indicador_mc2_quintil",
          type: "string"
        },
        {
          name: "@IDPERIODO",
          value: `%${idperiodo}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: `%${quintil}%`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getPaqueteIndicadorRangoMC2: (periodo_inicio, periodo_fin, distrito, provincia, quintil = "") => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_get_paquete_indicador_mc2_quintil",
          type: "string"
        },
        {
          name: "@PERIODO_INI",
          value: `${periodo_inicio}`,
          type: "string"
        },
        {
          name: "@PERIODO_FIN",
          value: `${periodo_fin}`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: `%${quintil}%`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getPaqueteIndicadorRangoMC1: (periodo_inicio, periodo_fin, distrito, provincia, quinti = "") => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_get_paquete_indicador_rango_mc1",
          type: "string"
        },
        {
          name: "@PERIODO_INI",
          value: `${periodo_inicio}`,
          type: "string"
        },
        {
          name: "@PERIODO_FIN",
          value: `${periodo_fin}`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: `%${quinti}%`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getAllByPeriodo: (sigla, provincia, distrito, periodo_ini, periodo_fin, periodoini, periodofin) => {
    var tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_grafica_idperido",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@PERIDO_INI",
          value: periodo_ini,
          type: "string"
        },
        {
          name: "@PERIOIDO_FIN",
          value: periodo_fin,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PER_FILT_INI",
          value: periodoini,
          type: "int"
        },
        {
          name: "@PER_FILT_FIN",
          value: periodofin,
          type: "int"
        },
      ]
    };

    return post(API_PUBLIC, request);
  },

  getFooterFuente: (idindicador) => {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "q_web_fuente_indicador",
          type: "string"
        },
        {
          name: "@IDINDICADOR",
          value: idindicador,
          type: "int"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getParametroPublic: (denominacion) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_parametro_by_denominacion_public",
          type: "string"
        },
        {
          name: "@PARAMETRO",
          value: denominacion,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getParametroIndicadorByClave(idindicador, clave) {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "sp_obtener_parametro_indicador_by_clave",
          type: "string"
        },
        {
          name: "@IDINDICADOR",
          value: idindicador,
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "clave",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${clave}%`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  /**
   * ZONA SOLO DESCARGA
   */

  // DESCARGA
  ReporteDetalleIndicadorv2: (payload, idindicador, sigla, _table, _titulo) => {
    var { filter, search, distrito, provincia, establecimiento, periodo_inicio, periodo_fin } =
      payload;

    if (periodo_fin == "") {
      var dd = new Date();

      var anio = dd.getFullYear();
      var mes = dd.getMonth() < 10 ? "0" + dd.getMonth() : dd.getMonth();

      periodo_fin = anio + "" + mes;
    }

    if (periodo_inicio == "") {
      periodo_inicio = "201201";
    }

    var $tabla = `sivi_data.${sigla}`;

    const request = {
      _type: 1,
      _table: _table,
      _title: _titulo,
      _petitions: [
        {
          name: "_script",
          value: "web_reporte_detalle_indicador",
          type: "int"
        },
        {
          name: "@TABLE",
          value: $tabla,
          type: "int"
        },
        {
          name: "@PERIODO_INI",
          value: `${periodo_inicio}`,
          type: "string"
        },
        {
          name: "@PERIODO_FIN",
          value: `${periodo_fin}`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value:
            "documento|concat(apellidopaterno, ' ', apellidomateno , ' , ' , nombre)|concat(apellidopaterno, ' ', apellidomateno , ' ' , nombre)",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    if (establecimiento == "") {
      request._petitions.push({
        name: "@filtros_AND",
        value: "numerador|distrito|provincia|i.idestablecimiento",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `%${filter}%|%${distrito}%|%${provincia}%|%${establecimiento}%`,
        type: "string"
      });
    } else if (establecimiento == -1) {
      request._petitions.push({
        name: "@filtros_AND",
        value: "numerador|distrito|provincia|i.red",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `%${filter}%|%${distrito}%|%${provincia}%|%TACNA%`,
        type: "string"
      });
    } else if (establecimiento == 0) {
      request._petitions.push({
        name: "@filtros_AND",
        value: "numerador|distrito|provincia|i.red",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `%${filter}%|%${distrito}%|%${provincia}%|%NO PERTENECE A NINGUNA RED%`,
        type: "string"
      });
    } else if (establecimiento == -2) {
      request._petitions.push({
        name: "@filtros_AND",
        value: "numerador|distrito|provincia",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `%${filter}%|%${distrito}%|%${provincia}%`,
        type: "string"
      });
    } else {
      request._petitions.push({
        name: "@filtros_AND",
        value: "numerador|distrito|provincia|i.idestablecimiento",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `%${filter}%|%${distrito}%|%${provincia}%|%${establecimiento}%`,
        type: "string"
      });
    }

    return post(API_SERVICE_REPORTEV2_COLUMNS_V2, request);
  },

  getReporteIndicador01: (distrito, anio, provincia, _table, _titulo, _fuente = "") => {
    const request = {
      _type: 1,
      _table: _table,
      _title: _titulo,
      _fuente: _fuente,
      _petitions: [
        {
          name: "_script",
          value: "dw_web_query_obtenersienanemia_public",
          type: "string"
        },
        {
          name: "@COLUMNS",
          value:
            "DEPARTAMENTO|PROVINCIA|DISTRITO|UBIGEO|N° DE EVALUADOS|ANEMIA TOTAL - N° DE CASOS|ANEMIA TOTAL - %|ANEMIA LEVE	- N° DE CASOS|ANEMIA LEVE	- %|ANEMIA MODERADA	- N° DE CASOS|ANEMIA MODERADA	- %|ANEMIA SEVERA - N° DE CASOS|ANEMIA SEVERA - %",
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@ANIO",
          value: `%${anio}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_REPORTEV2, request);
  },

  getReporteIndicador02: (
    distrito,
    anio,
    provincia,
    _table,
    _titulo,
    _fuente = "",
    fecha_corte
  ) => {
    const request = {
      _type: 1,
      _table: _table,
      _title: _titulo,
      _fuente: _fuente,
      _petitions: [
        {
          name: "_script",
          value: "dw_web_data_detalle_ind_registro_estadistica_fecha_corte",
          type: "string"
        },
        {
          name: "@COLUMNS",
          value:
            "MICRORED|NUMERADOR_TOTAL|DENOMINADOR_TOTAL|NUMERADOR_DISTRITO|DENOMINADOR_DISTRITO",
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@ANIO",
          value: `%${anio}%`,
          type: "string"
        },
        {
          name: "@TABLE",
          value: "sivi_data.ind_registro_fin2",
          type: "int"
        },
        {
          name: "@IDPERIODO",
          value: `%%`,
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: `${fecha_corte}`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_REPORTEV2, request);
  },

  // DOWLOAD

  getAllProvinciaNutricionGestante: (anio) => {
    const request = {
      _type: 1,
      _table: "reporte_provincia_anemia",
      _title: "TABLA DE GESTANTES CON ANEMIA",
      _valor: "PROVINCIA",
      _petitions: [
        {
          name: "_script",
          // value: "web_data_reporte_nutricional_provincia_v2",
          value: "web_data_reporte_padron_provincia_gestantes_anemia",
          type: "string"
        },
        {
          name: "@ANIO",
          value: `%${anio}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_REPORTEV2, request);
  },

  
  getAllDistritoNutricionGestante: (provincia, anio) => {
    const request = {
      _type: 1,
      _table: "reporte_distrito_anemia",
      _title: "TABLA DE GESTANTES CON ANEMIA",
      _valor: "DISTRITO",
      _petitions: [
        {
          name: "_script",
          // value: "web_data_reporte_nutricional_distrito_V2",
          value: "web_seg_select_anemia_v2_distrito_gestante",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@ANIO",
          value: `%${anio}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_REPORTEV2, request);
  },

  getAllEstablecimientoNutricionGestante: (provincia, distrito, anio) => {
    const request = {
      _type: 1,
      _table: "reporte_destablecimiento_anemia",
      _title: "TABLA DE GESTANTES CON ANEMIA",
      _valor: "ESTABLECIMIENTO",
      _petitions: [
        {
          name: "_script",
          // value: "web_data_reporte_nutricional_eess_v2",
          value: "web_seg_select_anemia_v2_establecimiento_gestante",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@ANIO",
          value: `%${anio}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_REPORTEV2, request);
  },
};
